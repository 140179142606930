import { AuthService } from '@myosh/myosh-login';
import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import axiosRetry from 'axios-retry';

interface StringKeyWithString {
  [key: string]: any;
}

export interface RequestOptions {
  url: string;
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  data?: any;
  headers?: StringKeyWithString;
  query?: any;
  cancelToken?: any;
}

export interface ApiResponse {
  success: boolean;
  payload: any;
}

const api = axios.create({});

const authService = new AuthService();

axiosRetry(api, {
  retries: 5,
  retryCondition: error => {
    if (error && !error.response) {
      return false;
    }

    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      let valid = true;
      authService
        .signinSilent()
        .then((userData: any) => {
          if (userData && userData.message) {
            authService.logout();
          }
          if (userData && userData.id_token) {
            localStorage.setItem('idToken', userData.id_token);
            valid = true;
          } else {
            valid = false;
          }
        })
        .catch(() => {
          valid = false;
        });

      return valid;
    } else {
      return false;
    }
  },
  retryDelay: () => {
    return 1500;
  },
});

api.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
  const idToken = localStorage.getItem('idToken');

  let headers = {
    Authorization: '',
  };

  if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }

  requestConfig.headers = { ...requestConfig.headers, ...headers };
  return requestConfig;
});

class Request {
  static async doRequest(options: RequestOptions): Promise<ApiResponse> {
    try {
      if (options.query) {
        const query = queryString.stringify(options.query);
        options.url = options.url + '?' + query;
        delete options.query;
      }
      const response = await api(options);
      return { success: true, payload: response };
    } catch (error: any) {
      if (error.response && error.response.data) {
        if (error.response.data.error) {
          return { success: false, payload: error.response.data.error };
        } else if (
          error.response.data.validation &&
          error.response.data.validation.errors &&
          error.response.data.validation.errors.length > 0
        ) {
          return {
            success: false,
            payload: error.response.data.validation.errors[0],
          };
        } else if (typeof error.response.data === 'string') {
          return { success: false, payload: error.response.data };
        } else {
          return { success: false, payload: error.message };
        }
      } else {
        return { success: false, payload: error.message };
      }
    }
  }
}

export default Request;
