import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Job } from './jobModels';
import {
  createJob,
  fetchAllJobs,
  deleteJob,
  enableJob,
  getJobById,
  updateJob,
} from './jobApi';
import { RootState } from '../../store';
import moment from 'moment';

export interface JobState {
  entities: any;
  entity: any;
  loading: boolean;
  errorMessage: string | null;
  successMessage: string | null;
}

const initialState: JobState = {
  entities: [],
  entity: null,
  loading: false,
  errorMessage: null,
  successMessage: null,
};

export const createJobAsync = createAsyncThunk(
  'job/createJob',
  async (options: { job: Job; schema: string; history: any }) => {
    const response = await createJob(options);
    return { response, history: options.history };
  },
);

export const getJobsAsync = createAsyncThunk(
  'job/fetchJobs',
  async (options: { job: any; schema: string }) => {
    const response = await fetchAllJobs({
      schema: options.schema,
      job: options.job,
    });
    return response;
  },
);

export const enableJobAsync = createAsyncThunk(
  'job/patchEnableJob',
  async (options: { job: Job; schema: any }) => {
    const response = await enableJob(options);
    return response;
  },
);

export const deleteJobAsync = createAsyncThunk(
  '/job/deleteJob',
  async (options: { id: string | number; schema?: string }, thunkAPI) => {
    const response = await deleteJob(options);

    if (response.success) {
      const schema = localStorage.getItem('schema') as string;
      await thunkAPI.dispatch(
        getJobsAsync({
          schema,
          job: {},
        }),
      );
    }
    return response;
  },
);

export const getJobByIdAsync = createAsyncThunk(
  'job/getJobById',
  async (id: number) => {
    const response = await getJobById({ id });

    if (response.success) return response;
  },
);

export const updateJobByIdAsync = createAsyncThunk(
  'job/updateJob',
  async (options: { job: Job; schema: any; history: any }, thunkAPI) => {
    const response = await updateJob(options);

    if (response.success) {
      const schema = localStorage.getItem('schema') as string;
      await thunkAPI.dispatch(
        getJobsAsync({
          schema,
          job: {},
        }),
      );
    }

    return { response, history: options.history };
  },
);

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    resetMessages: state => {
      state.loading = false;
      state.successMessage = null;
      state.errorMessage = null;
    },
    resetEntity: state => {
      state.loading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.entity = null;
    },
    sortEntitiesByParameter: (state, action) => {
      state.entities = state.entities.sort((entityA: any, entityB: any) => {
        if (action.payload.direction === 'ASC') {
          return moment(entityA[action.payload.property]).isBefore(
            moment(entityB[action.payload.property]),
          )
            ? -1
            : 1;
        } else {
          return moment(entityA[action.payload.property]).isBefore(
            moment(entityB[action.payload.property]),
          )
            ? 1
            : -1;
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createJobAsync.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(createJobAsync.fulfilled, (state, action) => {
        if (action.payload?.response.success) {
          state.loading = false;
          state.successMessage = action.payload.response.payload;
          state.errorMessage = null;
          state.entities.length = 0;

          setTimeout(() => {
            action.payload?.history && action.payload.history.push('/list');
          }, 500);
        } else {
          state.errorMessage = action.payload.response.payload;
          state.successMessage = null;
        }
      })
      .addCase(getJobsAsync.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(getJobsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = null;

        if (action.payload?.success) {
          const sortedJobs = action.payload?.payload?.data?.items?.sort(
            (entityA: any, entityB: any) => {
              return moment(entityA?.lastModified).isBefore(
                moment(entityB?.lastModified),
              )
                ? 1
                : -1;
            },
          );

          state.entities = sortedJobs;
        } else {
          state.errorMessage = action.payload?.payload;
        }
      })
      .addCase(deleteJobAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getJobByIdAsync.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(getJobByIdAsync.fulfilled, (state, action) => {
        state.loading = true;

        if (action.payload?.success) {
          state.loading = false;
          state.errorMessage = null;
          state.entity = action.payload?.payload?.data?.result;
        } else {
          state.errorMessage = action.payload?.payload;
        }
      })
      .addCase(updateJobByIdAsync.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(updateJobByIdAsync.fulfilled, (state, action) => {
        if (action.payload?.response.success) {
          state.loading = false;
          state.successMessage = action.payload.response.payload;
          state.entity = action.payload?.response.payload?.data?.result;

          setTimeout(() => {
            action.payload?.history && action.payload.history.push('/list');
          }, 500);
        } else {
          state.errorMessage = action.payload.response.payload;
          state.successMessage = null;
        }
      });
  },
});

export const selectLoading = (state: RootState): boolean => state.job.loading;

export const selectErrorMessage = (state: RootState): string | null =>
  state.job.errorMessage;

export const selectSuccessMessage = (state: RootState): string | null =>
  state.job.successMessage;

export const selectJobs = (state: RootState): any => state.job.entities;

export const selectJobById = (state: RootState): any => state.job.entity;

export const { resetMessages, resetEntity, sortEntitiesByParameter } =
  jobSlice.actions;

export default jobSlice.reducer;
