const env = process.env;

interface EnvironmentVariables {
  myoshBaseUrl: string;
  myoshApiKey: string;
}

const environmentVariables: EnvironmentVariables = {
  myoshBaseUrl: env.REACT_APP_API_V4 as string,
  myoshApiKey: env.REACT_APP_API_KEY as string,
};

export default environmentVariables;
