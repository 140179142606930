import { useUser } from '@myosh/myosh-login';
import MainLayout from './pages/MainLayout';
import RoutesContainer from './routes/RoutesContainer';

function App() {
  const { state } = useUser();
  const isAuthenticated = state.auth;

  return (
    <div className="App">
      {isAuthenticated && <MainLayout />}
      <RoutesContainer />
    </div>
  );
}

export default App;
