type routeItem = {
  path: string;
  loader: () => Promise<any>;
};

const privateRoutes: routeItem[] = [
  {
    path: '/list',
    loader: () => import('../pages/JobsList'),
  },
  {
    path: '/create',
    loader: () => import('../pages/CreateJob'),
  },
  {
    path: '/edit/:id',
    loader: () => import('../pages/EditJob'),
  },
];

export default { privateRoutes };
