import { Route, Redirect, useLocation } from 'react-router-dom';
import { useUser } from '@myosh/myosh-login';

export function PrivateRoute({ component: Component, ...rest }: any) {
  const { state } = useUser();
  const location = useLocation();

  const isAuthenticated = state.auth;

  return (
    <Route
      {...rest}
      render={props => {
        const initalParams = location.search;

        if (initalParams && initalParams.length > 0) {
          localStorage.setItem('initialParams', initalParams);
        }

        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
