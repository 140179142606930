import { useEffect, useState } from 'react';
import querystring from 'query-string';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import routes from '../routes';
import { Login, LoginCallback, LogoutCallback } from '@myosh/myosh-login';
import { useUser } from '@myosh/myosh-login';
import PrivateRoute from '../PrivateRoute';
import CustomLoadable from '../../components/shared/CustomLoadable';
import UsernameGuard from '../../guards/UsernameGuard';

export function RoutesContainer() {
  const { state } = useUser();
  const isAuthenticated = state.auth;
  const [username, setUsername] = useState<string>('');

  const location = useLocation();
  const history = useHistory();

  const onLoginSuccess = (results: any) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('refreshToken', results.refreshToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    const storedInitialParams = localStorage.getItem('initialParams');

    history.push(`/list${storedInitialParams ? storedInitialParams : ''}`);
  };

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);
      if (parsedQuery && Object.keys(parsedQuery).length > 0) {
        if (parsedQuery.userName) {
          setUsername(parsedQuery.userName as string);
        }

        if (parsedQuery.username) {
          setUsername(parsedQuery.username as string);
        }
      }
    }
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          const initalParams = location.search;
          if (initalParams && initalParams.length > 0) {
            localStorage.setItem('initialParams', initalParams);
          }
          return isAuthenticated ? (
            <Redirect to="/list" />
          ) : (
            <Redirect to="/login" />
          );
        }}
      />
      <Route path="/auth/cb/logout">
        <LogoutCallback />
      </Route>
      <Route path="/auth/cb/silent">
        <div>Silent renewal callback</div>
      </Route>
      <Route path="/auth/cb">
        <LoginCallback />
      </Route>
      <Route path="/login">
        <Login onLoginSuccess={onLoginSuccess} username={username} />
      </Route>
      {routes.privateRoutes.map(route => (
        <PrivateRoute
          key={route.path}
          exact
          path={route.path}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}
    </Switch>
  );
}
