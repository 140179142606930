import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchModulesWithoutForms, fetchModuleWithForms } from './moduleApi';
import { RootState } from '../../store';

interface Module {
  id: number;
  caption: any;
  viewId: string;
  type: 'MODULE' | 'EMBEDDED_URL';
  icon: string;
  sourceUrl: string;
  displayInMobile: boolean;
  target: 'CURRENT_WINDOW' | 'NEW_WINDOW';
  roles: Array<any>;
  removed: boolean;
  hidden: boolean;
  position: number;
  forms?: Array<ModuleForm>;
}

interface ModuleForm {
  id: number;
  caption: any;
  removed: boolean;
  workflowId: number;
  roles: Array<any>;
  workflowSteps: Array<any>;
  progressConditions: Array<any>;
  displayInForms: boolean;
  sourceId: string;
}

export interface ModuleState {
  moduleWithForms: Module | null;
  modulesWithoutForms: Array<Module>;
  loadingPayload: {
    fetchingModulesWithoutFormsLoading: boolean;
    fetchingModuleWithFormsLoading: boolean;
  };
  errorPayload: {
    fetchingModulesWithoutFormsError: string | null;
    fetchingModuleWithFormsError: string | null;
  };
}

const initialState: ModuleState = {
  moduleWithForms: null,
  modulesWithoutForms: [],
  loadingPayload: {
    fetchingModulesWithoutFormsLoading: false,
    fetchingModuleWithFormsLoading: false,
  },
  errorPayload: {
    fetchingModulesWithoutFormsError: null,
    fetchingModuleWithFormsError: null,
  },
};

export const fetchModulesWithoutFormsAsync = createAsyncThunk(
  'module/fetchModulesWithoutForms',
  async (options: { schema: string }) => {
    const response = await fetchModulesWithoutForms({
      schema: options.schema,
    });
    return response;
  },
);

export const fetchModuleWithFormsAsync = createAsyncThunk(
  'module/fetchModuleWithForms',
  async (options: { moduleId: number; schema: string }) => {
    const response = await fetchModuleWithForms(options);
    return response;
  },
);

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchModulesWithoutFormsAsync.pending, state => {
        state.loadingPayload.fetchingModulesWithoutFormsLoading = true;
      })
      .addCase(fetchModulesWithoutFormsAsync.fulfilled, (state, action) => {
        state.loadingPayload.fetchingModulesWithoutFormsLoading = false;

        const sortedModules = action.payload.payload?.data?.items?.sort(
          (moduleA: any, moduleB: any) => {
            return (
              moduleA.caption?.translations[0]?.value as string
            ).localeCompare(moduleB.caption?.translations[0]?.value as string);
          },
        );

        if (action.payload.success) {
          state.errorPayload.fetchingModulesWithoutFormsError = null;
          state.modulesWithoutForms = sortedModules;
        } else {
          state.errorPayload.fetchingModulesWithoutFormsError =
            action.payload.payload?.message;
        }
      })
      .addCase(fetchModuleWithFormsAsync.pending, state => {
        state.loadingPayload.fetchingModuleWithFormsLoading = true;
      })
      .addCase(fetchModuleWithFormsAsync.fulfilled, (state, action) => {
        state.loadingPayload.fetchingModuleWithFormsLoading = false;

        const modulePayload = action.payload.payload?.data?.result;

        const sortedForms = modulePayload.forms?.sort(
          (formA: any, formB: any) => {
            return (
              formA.caption?.translations[0]?.value as string
            ).localeCompare(formB.caption?.translations[0]?.value as string);
          },
        );

        if (action.payload.success) {
          state.errorPayload.fetchingModuleWithFormsError = null;
          state.moduleWithForms = {...modulePayload, forms: sortedForms};
        } else {
          state.errorPayload.fetchingModuleWithFormsError =
            action.payload.payload?.message;
        }
      });
  },
});

export const selectModulesWithoutForms = (state: RootState): Array<Module> =>
  state.module.modulesWithoutForms;

export const selectModuleWithForms = (state: RootState): Module | null =>
  state.module.moduleWithForms;

export const selectLoadingPayload = (state: RootState): any =>
  state.module.loadingPayload;

export const selectErrorPayload = (state: RootState): any =>
  state.module.errorPayload;

export default moduleSlice.reducer;
