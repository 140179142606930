import request from '../../../helpers/request';
import environment from '../../../constants/environment';
import { Job } from './jobModels';

interface CreateJobRequestOptions {
  job: Job;
  schema?: string;
}

interface DeleteJobRequestOptions {
  id: string | number;
  schema?: string;
}

interface GetJobRequestOptions {
  id: string | number;
  schema?: string;
}

export function createJob(options: CreateJobRequestOptions) {
  return request.doRequest({
    method: 'post',
    url: environment.myoshBaseUrl + 'scheduled-jobs/export/configurations',
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': options.schema,
    },
    data: options.job,
  });
}

export function fetchAllJobs(options: CreateJobRequestOptions) {
  return request.doRequest({
    method: 'get',
    url: environment.myoshBaseUrl + `scheduled-jobs/export/configurations`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': options.schema,
    },
  });
}

export function enableJob(options: CreateJobRequestOptions) {
  return request.doRequest({
    method: 'patch',
    url:
      environment.myoshBaseUrl +
      `scheduled-jobs/export/configurations/${options.job.id}`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': localStorage.getItem('schema'),
    },
    data: options.job,
  });
}

export function deleteJob(options: DeleteJobRequestOptions) {
  return request.doRequest({
    method: 'patch',
    url:
      environment.myoshBaseUrl +
      `scheduled-jobs/export/configurations/${options.id}`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': options.schema,
    },
    data: {
      removed: true,
    },
  });
}

export function getJobById(options: GetJobRequestOptions) {
  return request.doRequest({
    method: 'get',
    url:
      environment.myoshBaseUrl +
      `scheduled-jobs/export/configurations/${options.id}`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': localStorage.getItem('schema'),
    },
  });
}

export function updateJob(options: CreateJobRequestOptions) {
  return request.doRequest({
    method: 'patch',
    url:
      environment.myoshBaseUrl +
      `scheduled-jobs/export/configurations/${options.job.id}`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': localStorage.getItem('schema'),
    },
    data: options.job,
  });
}
