import React from 'react';
import LoadingComponent from '../LoadingComponent';
import UsernameGuard from '../../../guards/UsernameGuard';

export function CustomLoadable(opts: any) {
  const LazyComponent = React.lazy(opts.loader);

  return (props: any) => (
    <React.Suspense fallback={<LoadingComponent />}>
      <UsernameGuard>
        <LazyComponent {...props} />
      </UsernameGuard>
    </React.Suspense>
  );
}
