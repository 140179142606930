import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
export const MainLayout: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <main className="main-container">
      <nav className="flex-container flex-container-center navigation">
        <div className="logo">
          <NavLink to="/">
            <span className="secondary-text logo-1">{t('bulk')}</span>
            <span className="primary-text">{t('export')}</span>
          </NavLink>
        </div>
        <NavLink to="/">
          <span className="secondary-text ">{t('manage')}</span>
        </NavLink>
      </nav>
    </main>
  );
};
