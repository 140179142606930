import request from '../../../helpers/request';
import environment from '../../../constants/environment';

interface BaseModuleSliceRequestOptions {
  schema?: string;
  query?: any;
}

interface ModuleRequestOptions extends BaseModuleSliceRequestOptions {}

interface FormRequestOptions extends BaseModuleSliceRequestOptions {
  moduleId: string | number;
}

export function fetchModulesWithoutForms(options: ModuleRequestOptions) {
  return request.doRequest({
    method: 'get',
    url: environment.myoshBaseUrl + 'modules',
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': options.schema,
    },
    query: options.query,
  });
}

export function fetchModuleWithForms(options: FormRequestOptions) {
  return request.doRequest({
    method: 'get',
    url: environment.myoshBaseUrl + `modules/${options.moduleId}`,
    headers: {
      'x-api-key': environment.myoshApiKey,
      'myosh-schema': options.schema,
    },
    query: options.query,
  });
}
