import moduleReducer from './slices/module/moduleSlice';
import jobReducer from './slices/job/jobSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { getJobsAsync } from './slices/job/jobSlice';

const rootReducer = combineReducers({
  module: moduleReducer,
  job: jobReducer,
  getJobs: getJobsAsync,
});

export default rootReducer;
